import React, { useContext } from "react";
import { GlobalDataContext } from "../../context/context";

function Directories() {
  const { rpdata } = useContext(GlobalDataContext);
  return (
    <div>
      <div className="px-4 py-8">
        <section className="flex justify-center pb-20">
          <img
            src="https://firebasestorage.googleapis.com/v0/b/imagenessanti-2a052.appspot.com/o/CUPON-Joshua.png?alt=media&token=434fcb36-9044-4b6b-8ada-ec4fe0ed9ccb"
            alt="cupon"
            className="w-[350px] lg:w-[450px]"
          />
        </section>
        <div className="flex justify-center gap-5 flex-wrap items-center pb-5">
          <img
            src="https://firebasestorage.googleapis.com/v0/b/imagenessanti-2a052.appspot.com/o/Joshua-Landscaping-Snow-Removal-cupon5.png?alt=media&token=17b7fd02-d33d-4ff8-9c13-e6c128ed70fa"
            alt="cupon"
            className="w-[350px]"
          />
          {rpdata?.gmb?.link.length > 1 ? (
            <a
              href={rpdata?.gmb?.link}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={rpdata?.gmb?.img} alt="Not found" width={"250px"} />
            </a>
          ) : null}
          <img
            src="https://firebasestorage.googleapis.com/v0/b/imagenessanti-2a052.appspot.com/o/Joshua-Landscaping-Snow-Removal-10_.png?alt=media&token=733296d3-b21a-407b-ae4a-aaaf848603e9"
            alt="cupon"
            className="w-[350px]"
          />
        </div>
        {rpdata?.dbSocialMedia?.redes.length > 0 ||
        rpdata?.dbSocialMedia?.directorios.length > 0 ? (
          <h2 className="text-center pb-5">Find Us On</h2>
        ) : null}

        <div
          className={`w-4/5 mx-auto flex flex-wrap justify-center items-center gap-4 pb-5`}
        >
          {rpdata?.dbSocialMedia?.directorios.length > 0
            ? rpdata?.dbSocialMedia?.directorios.map((item, index) => {
                return (
                  <div className="text-center content-directorios" key={index}>
                    <a
                      href={item.url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img src={item.image} alt="Not found" width={"250px"} />
                    </a>
                  </div>
                );
              })
            : null}
          {rpdata?.dbSocialMedia?.redes.length > 0
            ? rpdata?.dbSocialMedia?.redes.map((item, index) => {
                return (
                  <div className="text-center content-directorios" key={index}>
                    <a
                      href={item.url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img src={item.image} alt="Not Found" width={"250px"} />
                    </a>
                  </div>
                );
              })
            : null}
        </div>
      </div>
      {/* <div className={`grid ${rpdata?.dbSocialMedia?.directorios?.length > 3 || rpdata?.dbSocialMedia?.redes?.length > 3 ? 'md:grid-cols-4' : 'md:grid-flow-col' } grid-cols-1 gap-4 bg-auto md:bg-contain bg-top-[100px]`}> */}
    </div>
  );
}

export default Directories;
